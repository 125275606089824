import React from 'react'

// React Icons
import { FaGithub } from 'react-icons/fa'

// Components
import ExternalLink from './ExternalLink'

const GithubEdit = repoPath => {
  if (repoPath.includes('/content/blog') && process.env.GATSBY_GITHUB_REPO !== undefined) {
    const strip = repoPath.replace(/^.*\/content/g, `${process.env.GATSBY_GITHUB_REPO}/content`)

    return (
      <>
        <ExternalLink className="github-edit" href={strip} title="Github">
          {<FaGithub />}
        </ExternalLink>
      </>
    )
  }
  return
}

export default GithubEdit
