import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

// React Icons
import { FaHome, FaArrowUp } from 'react-icons/fa'

const scrollToTop = () => {
  return window.scrollTo({ top: 0, behavior: 'smooth' })
}

const PostScroll = ({ title, slug }) => {
  const [scroll, setScroll] = useState(1)

  // running on mount
  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 800
      if (scrollCheck !== scroll) setScroll(scrollCheck)
    }

    // setting the event handler from web API
    document.addEventListener('scroll', onScroll)

    // cleaning up from the web API
    return () => document.removeEventListener('scroll', onScroll)
  }, [scroll, setScroll])

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${
          scroll === true ? 'isFixed' : 'notVisible'
        }`}
        id="backTopNav"
      >
        <div className="container">
          <div className="col-lg-8 col-md-10 mx-auto mt-1 mb-1">
            <div className="iconLinks">
              <Link to="/">
                <FaHome />
              </Link>
              <span
                className="backtotop"
                onClick={scrollToTop}
                onKeyDown={scrollToTop}
                aria-hidden="true"
              >
                <FaArrowUp />
              </span>
            </div>

            <div className="thisTitle">
              <Link to={`/${slug}`}>{title}</Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default PostScroll
