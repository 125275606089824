import React from 'react'
import { Link } from 'gatsby'

// utils
import tagResolve from '../../utils/catResolve'

const TagSpread = ({ tags }) => {
  if (!Array.isArray(tags) || !tags.length) return ''

  const tagLength = tags.length

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto">
            <small className="postTags">
              Tags:{' '}
              {tags.map((tag, key) => {
                return (
                  <React.Fragment key={key}>
                    <Link to={`/tag/${tagResolve(tag)}`}>{tagResolve(tag)}</Link>
                    {key !== tagLength - 1 ? `, ` : ''}
                  </React.Fragment>
                )
              })}
            </small>
          </div>
        </div>
      </div>
    </>
  )
}

export default TagSpread
