import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

// Components
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import CatSpread from '../components/CatSpread'
import PostDate from '../components/PostDate'
import TimeRead from '../components/TimeRead'
import GithubEdit from '../components/GithubEdit'
import TagSpread from '../components/TagSpread'
import PostScroll from '../components/PostScroll'

const singlePost = props => {
  const fm = props.data.mdx.frontmatter
  const cats = props.data.mdx.frontmatter.category

  return (
    <>
      <Seo
        title={fm.title}
        description={fm.description}
        url={fm.slug}
        keywords={cats.concat(fm.tags)}
      />
      <Layout>
        <PostScroll url={props.location.href} title={fm.title} slug={fm.slug} />
        <header className="masthead" style={{ backgroundColor: '#2f4167' }}>
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="post-heading">
                  <h1>{fm.title}</h1>
                  <div className="meta">
                    {GithubEdit(props.data.mdx.fileAbsolutePath)} Posted in{' '}
                    <CatSpread cats={cats} /> on <PostDate date={fm.date} />{' '}
                    <TimeRead time={props.data.mdx.timeToRead} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <article>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                {fm.description}
                <hr />
                <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
              </div>
            </div>
          </div>
          <TagSpread tags={fm.tags} />
        </article>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query SinglePostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
      timeToRead
      frontmatter {
        date
        description
        title
        slug
        category
        tags
      }
      fileAbsolutePath
    }
  }
`

export default singlePost
